"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseAggRepository = void 0;
const base_entity_1 = require("./base.entity");
class BaseAggRepository {
    remote;
    local;
    constructor(remote, local) {
        this.remote = remote;
        this.local = local;
    }
    async getItem({ id, source, version, }) {
        if (source === base_entity_1.EntitySourceType.Local) {
            return this.local.getItem({ id, version });
        }
        else if (source === base_entity_1.EntitySourceType.Origin) {
            return this.remote.getItem({ id, version });
        }
        else {
            // ToDo: why local is preferred?
            const localItem = await this.local.getItem({ id, version });
            if (localItem)
                return localItem;
            return this.remote.getItem({ id, version });
        }
    }
    async getItems(options) {
        const localItems = await this.local.getItems(options);
        const remoteItems = await this.remote.getItems(options);
        return [...localItems, ...remoteItems];
    }
    async getItemsByIndex(entity) {
        const localItems = await this.local.getItemsByIndex(entity);
        const remoteItems = await this.remote.getItemsByIndex(entity);
        return [...localItems, ...remoteItems];
    }
    async createItem(item, tx) {
        if (item.source === base_entity_1.EntitySourceType.Local) {
            return this.local.createItem(item);
        }
        else if (item.source === base_entity_1.EntitySourceType.Origin) {
            const result = await this.remote.createItem(item, tx);
            await this._deleteLocalItemIfExist(item.id);
            await this._deleteLocalItemIfExist(`/${item.entityType}/${item.localId}`); // entities created without a wallet
            return result;
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async editItem(item, tx) {
        if (item.source === base_entity_1.EntitySourceType.Local) {
            return this.local.editItem(item);
        }
        else if (item.source === base_entity_1.EntitySourceType.Origin) {
            const result = await this.remote.editItem(item, tx);
            await this._deleteLocalItemIfExist(item.id);
            return result;
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async saveItem(item, tx) {
        if (item.source === base_entity_1.EntitySourceType.Local) {
            return this.local.saveItem(item);
        }
        else if (item.source === base_entity_1.EntitySourceType.Origin) {
            const result = await this.remote.saveItem(item, tx);
            await this._deleteLocalItemIfExist(item.id);
            return result;
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async deleteItem(id) {
        // ToDo: not obvious that local will be deleted
        await this.local.deleteItem(id);
    }
    async constructItem(item) {
        if (item.source === base_entity_1.EntitySourceType.Local) {
            return this.local.constructItem(item);
        }
        else if (item.source === base_entity_1.EntitySourceType.Origin) {
            return this.remote.constructItem(item);
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async getTagValue({ id, source, tag, }) {
        if (source === base_entity_1.EntitySourceType.Local) {
            return this.local.getTagValue({ id, tag });
        }
        else if (source === base_entity_1.EntitySourceType.Origin) {
            return this.remote.getTagValue({ id, tag });
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async getTags({ id, source }) {
        if (source === base_entity_1.EntitySourceType.Local) {
            return this.local.getTags({ id });
        }
        else if (source === base_entity_1.EntitySourceType.Origin) {
            return this.remote.getTags({ id });
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async getVersions({ id, source, }) {
        if (source === base_entity_1.EntitySourceType.Local) {
            return this.local.getVersions({ id });
        }
        else if (source === base_entity_1.EntitySourceType.Origin) {
            return this.remote.getVersions({ id });
        }
        else {
            throw new Error('Invalid source');
        }
    }
    async _deleteLocalItemIfExist(id) {
        if (await this.local.getItem({ id })) {
            await this.local.deleteItem(id);
        }
    }
}
exports.BaseAggRepository = BaseAggRepository;
