"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNearConfig = exports.NearConfigs = void 0;
exports.NearConfigs = {
    mainnet: {
        networkId: 'mainnet',
        nodeUrl: 'https://mainnet.near.dapplets.org',
        contractName: 'social.dapplets.near',
        walletUrl: 'https://app.mynearwallet.com',
        defaultMutationId: 'bos.dapplets.near/mutation/Sandbox',
        layoutManagers: {
            vertical: 'bos.dapplets.near/widget/VerticalLayoutManager',
            horizontal: 'bos.dapplets.near/widget/DefaultLayoutManager',
            ear: 'bos.dapplets.near/widget/ContextActionsGroup',
        },
        timeReference: {
            timestamp: 1733844432230,
            height: 134671239,
            avgBlockTime: 1091, // https://nearblocks.io/
        },
    },
    testnet: {
        networkId: 'testnet',
        nodeUrl: 'https://testnet.near.dapplets.org',
        contractName: 'social.dapplets.testnet',
        walletUrl: 'https://testnet.mynearwallet.com',
        defaultMutationId: 'bos.dapplets.testnet/mutation/Sandbox',
        layoutManagers: {
            vertical: 'bos.dapplets.testnet/widget/VerticalLayoutManager',
            horizontal: 'bos.dapplets.testnet/widget/DefaultLayoutManager',
            ear: 'bos.dapplets.testnet/widget/ContextActionsGroup',
        },
        timeReference: {
            timestamp: 1733844492601,
            height: 181770025,
            avgBlockTime: 1000, // https://testnet.nearblocks.io/
        },
    },
};
const getNearConfig = (networkId) => {
    const config = exports.NearConfigs[networkId];
    if (!config)
        throw new Error(`Unknown networkId ${networkId}`);
    return config;
};
exports.getNearConfig = getNearConfig;
