"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkParser = void 0;
function tryParseUrl(text) {
    try {
        return new URL(text);
    }
    catch (_) {
        return null;
    }
}
class LinkParser {
    parseContext(element, contextName) {
        if (contextName === 'root')
            return { id: 'global' };
        // ToDo: twitter-specific logic. Twitter adds '…' to the end of the url
        const cleanUrl = element.innerText.replace(/…$/g, '');
        return {
            id: element.href,
            href: element.href,
            innerHref: tryParseUrl(cleanUrl)?.href,
            innerText: element.innerText,
        };
    }
    findChildElements(element) {
        // Using <a> inside of <a> is not valid, so only one level of nesting is possible
        if (element.tagName === 'A')
            return [];
        return Array.from(element.querySelectorAll('a')).map((element) => ({
            element,
            contextName: 'link',
        }));
    }
    findInsertionPoint() {
        // No insertions points in blinks
        return null;
    }
    getInsertionPoints() {
        // No insertions points in blinks
        return [];
    }
}
exports.LinkParser = LinkParser;
