"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Transaction = void 0;
const merge_deep_1 = require("../../common/merge-deep");
class Transaction {
    _socialDb;
    _state = {};
    constructor(_socialDb) {
        this._socialDb = _socialDb;
    }
    queue(data) {
        this._state = (0, merge_deep_1.mergeDeep)(this._state, data);
    }
    async commit() {
        await this._socialDb.set(this._state);
    }
}
exports.Transaction = Transaction;
