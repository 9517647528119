"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkDbService = void 0;
const link_db_entity_1 = require("./link-db.entity");
const user_link_service_1 = require("../user-link/user-link.service");
const base_entity_1 = require("../base/base.entity");
const DefaultIndexRules = {
    namespace: true,
    type: true,
    parsed: { id: true },
};
const ContextLinkKey = 'ctxlink';
const KeyDelimiter = '/';
class LinkDbService {
    _linkDbRepository;
    constructor(_linkDbRepository) {
        this._linkDbRepository = _linkDbRepository;
    }
    async set(mutationId, appId, docId, context, // ToDo: replace with IContextNode?
    dataByAccount, indexRules = DefaultIndexRules, tx) {
        const accounts = Object.keys(dataByAccount);
        // ToDo: implement multiple accounts
        if (accounts.length !== 1) {
            throw new Error('Only one account can be written at a time');
        }
        const [accountId] = accounts;
        const indexObject = LinkDbService._buildLinkIndex(mutationId, appId, docId, indexRules, context);
        const index = user_link_service_1.UserLinkService._hashObject(indexObject); // ToDo: the dependency is not injected
        const globalId = [accountId, ContextLinkKey, index].join(KeyDelimiter);
        const ctxLink = link_db_entity_1.CtxLink.create({
            id: globalId,
            index: indexObject,
            data: dataByAccount[accountId],
            source: base_entity_1.EntitySourceType.Origin,
        });
        await this._linkDbRepository.saveItem(ctxLink, tx);
    }
    async get(mutationId, appId, docId, context, accountIds, // from any user by default
    indexRules = DefaultIndexRules // use context id as index by default
    ) {
        const indexObject = LinkDbService._buildLinkIndex(mutationId, appId, docId, indexRules, context);
        const index = user_link_service_1.UserLinkService._hashObject(indexObject); // ToDo: the dependency is not injected
        let ctxLinks;
        if (!accountIds) {
            ctxLinks = await this._linkDbRepository.getItems({ localId: index });
        }
        else {
            accountIds = Array.isArray(accountIds) ? accountIds : [accountIds];
            const ctxLinkIds = accountIds.map((accountId) => [accountId, ContextLinkKey, index].join(KeyDelimiter));
            // ToDo: too much data will be retrieved here, becuase it created by users
            const ctxLinksNullPossible = await Promise.all(ctxLinkIds.map((id) => this._linkDbRepository.getItem({ id })));
            ctxLinks = ctxLinksNullPossible.filter((x) => x !== null);
        }
        const dataByAuthor = Object.fromEntries(ctxLinks.map((ctxLink) => [ctxLink.authorId, ctxLink.data]));
        return dataByAuthor;
    }
    static _buildLinkIndex(mutationId, appId, documentId, indexRules, context) {
        const index = {
            context: LinkDbService._buildIndexedContextValues(indexRules, context),
        };
        // ToDo: non-obvious indexing. Documents are mutation-independent
        if (documentId) {
            // Document can be reused in different mutations and apps
            index.documentId = documentId;
        }
        else {
            // MutationId is a part of the index.
            // It means that a data of the same application is different in different mutations
            index.mutationId = mutationId;
            index.appId = appId;
        }
        return index;
    }
    static _buildIndexedContextValues(indexes, values) {
        const out = {};
        for (const prop in indexes) {
            if (!indexes[prop])
                continue;
            // ToDo: will not work with arrays
            if (typeof values[prop] === 'object') {
                out[prop] = LinkDbService._buildIndexedContextValues(indexes[prop], values[prop]);
            }
            else {
                out[prop] = values[prop];
            }
        }
        return out;
    }
}
exports.LinkDbService = LinkDbService;
