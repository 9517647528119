"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderService = void 0;
class OrderService {
    client;
    constructor(client) {
        this.client = client;
    }
    create = async (createOrderDto) => {
        return this.client.post('order', createOrderDto);
    };
    findAll = async () => {
        return this.client.get('order');
    };
    get = (id) => {
        return this.client.get(`order/${id}`);
    };
    update = (updateOrderDto) => {
        return this.client.patch(`order/${updateOrderDto.id}`, updateOrderDto);
    };
    remove = (id) => {
        return this.client.delete(`order/${id}`);
    };
}
exports.OrderService = OrderService;
