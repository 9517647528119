"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Base = exports.EntitySourceType = void 0;
const entity_1 = require("./decorators/entity");
const KeyDelimiter = '/';
var EntitySourceType;
(function (EntitySourceType) {
    EntitySourceType["Local"] = "local";
    EntitySourceType["Origin"] = "origin";
})(EntitySourceType || (exports.EntitySourceType = EntitySourceType = {}));
class Base {
    id = '';
    source = EntitySourceType.Local;
    blockNumber = 0; // ToDo: fake block number
    timestamp = 0; // ToDo: fake timestamp
    version = '0'; // ToDo: fake version?
    get authorId() {
        return this.id.split(KeyDelimiter)[0] ?? null;
    }
    set authorId(authorId) {
        this.id = [authorId, this.entityType, this.localId].join(KeyDelimiter);
    }
    get localId() {
        return this.id.split(KeyDelimiter)[2];
    }
    set localId(localId) {
        this.id = [this.authorId, this.entityType, localId].join(KeyDelimiter);
    }
    get entityType() {
        return (0, entity_1.getEntity)(this.constructor).name;
    }
    static create(data) {
        const instance = new this();
        Object.assign(instance, data);
        if (data.authorId)
            instance.authorId = data.authorId; // ToDo: hit fix: think of it
        return instance;
    }
    copy(data) {
        const copyInstance = new this.constructor();
        Object.assign(copyInstance, this, data);
        return copyInstance;
    }
    toDto() {
        return {
            id: this.id,
            localId: this.localId,
            authorId: this.authorId,
            source: this.source,
            blockNumber: this.blockNumber,
            timestamp: this.timestamp,
            version: this.version,
        };
    }
}
exports.Base = Base;
