"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Entity = Entity;
exports.getEntity = getEntity;
require("reflect-metadata/lite");
const Key = Symbol('entity');
function Entity(options) {
    return Reflect.metadata(Key, options);
}
function getEntity(target) {
    return Reflect.getMetadata(Key, target);
}
