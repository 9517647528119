"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Mutation = void 0;
const base_entity_1 = require("../base/base.entity");
const column_1 = require("../base/decorators/column");
const entity_1 = require("../base/decorators/entity");
let Mutation = class Mutation extends base_entity_1.Base {
    metadata = {};
    apps = [];
    targets = [];
    toDto() {
        return {
            ...super.toDto(),
            metadata: this.metadata,
            apps: this.apps,
            targets: this.targets,
        };
    }
};
exports.Mutation = Mutation;
__decorate([
    (0, column_1.Column)(),
    __metadata("design:type", Object)
], Mutation.prototype, "metadata", void 0);
__decorate([
    (0, column_1.Column)({
        type: column_1.ColumnType.Json,
        versioned: true,
        transformer: { from: normalizeApps, to: denormalizeApps },
    }),
    __metadata("design:type", Array)
], Mutation.prototype, "apps", void 0);
__decorate([
    (0, column_1.Column)({ type: column_1.ColumnType.Json, versioned: true }),
    __metadata("design:type", Array)
], Mutation.prototype, "targets", void 0);
exports.Mutation = Mutation = __decorate([
    (0, entity_1.Entity)({ name: 'mutation', versioned: true })
], Mutation);
function normalizeApps(apps) {
    return apps.map((app) => (typeof app === 'string' ? { appId: app, documentId: null } : app));
}
function denormalizeApps(apps) {
    return apps.map((app) => (app.documentId ? app : app.appId));
}
