"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InMemoryStorage = void 0;
class InMemoryStorage {
    _state = new Map();
    async getItem(key) {
        return this._state.get(key) ?? null;
    }
    async setItem(key, value) {
        this._state.set(key, value);
    }
    async removeItem(key) {
        this._state.delete(key);
    }
    async getAllKeys() {
        return Array.from(this._state.keys());
    }
}
exports.InMemoryStorage = InMemoryStorage;
