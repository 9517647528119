"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitOfWorkService = void 0;
const transaction_1 = require("./transaction");
class UnitOfWorkService {
    _socialDb;
    constructor(_socialDb) {
        this._socialDb = _socialDb;
    }
    async runInTransaction(callback) {
        const tx = new transaction_1.Transaction(this._socialDb);
        const result = await callback(tx);
        await tx.commit();
        // ToDo: revert changes
        return result;
    }
}
exports.UnitOfWorkService = UnitOfWorkService;
