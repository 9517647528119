"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BosParser = void 0;
exports.replaceMustaches = replaceMustaches;
const CompAttr = 'data-component';
const PropsAttr = 'data-props';
class BosParser {
    config;
    constructor(config) {
        // ToDo: validate config
        this.config = config;
        if (!this.config['root']) {
            this.config['root'] = {
                props: {
                    id: 'root',
                },
                children: Object.keys(this.config), // ToDo:
            };
        }
    }
    parseContext(element, contextName) {
        const contextProperties = this.config[contextName].props;
        if (!contextProperties)
            return {};
        const parsed = {};
        const bosProps = JSON.parse(element.getAttribute(PropsAttr) ?? '{}');
        for (const [prop, mustacheTemplate] of Object.entries(contextProperties)) {
            const value = replaceMustaches(mustacheTemplate, { props: bosProps });
            parsed[prop] = value;
        }
        return parsed;
    }
    findChildElements(element, contextName) {
        const contextConfig = this.config[contextName];
        if (!contextConfig.children?.length)
            return [];
        const result = [];
        // ToDo: maybe querySelectorAll('.foo:not(.foo .foo)') is faster?
        for (const childContextName of contextConfig.children ?? []) {
            const childConfig = this.config[childContextName];
            if (!childConfig.component)
                continue;
            const childElements = Array.from(element.querySelectorAll(`[${CompAttr}="${childConfig.component}"]`));
            for (const childElement of childElements) {
                result.push({ element: childElement, contextName: childContextName });
            }
        }
        return result;
    }
    findInsertionPoint(element, contextName, insertionPoint) {
        const contextConfig = this.config[contextName];
        const insPointConfig = contextConfig.insertionPoints?.[insertionPoint];
        if (insPointConfig?.component) {
            return element.querySelector(`[${CompAttr}="${insPointConfig.component}"]`);
        }
        else if (insPointConfig) {
            // if `component` is not defined use self
            return element;
        }
        else {
            return null;
        }
    }
    getInsertionPoints(_, contextName) {
        const contextConfig = this.config[contextName];
        if (!contextConfig.insertionPoints)
            return [];
        return Object.entries(contextConfig.insertionPoints).map(([name, selectorOrObject]) => ({
            name,
            insertionType: selectorOrObject.insertionType,
            bosLayoutManager: selectorOrObject.bosLayoutManager,
        }));
    }
}
exports.BosParser = BosParser;
/**
 * Executes a template string by replacing placeholders with corresponding values from the provided data object.
 *
 * @param {string} template - The template string containing placeholders in the format '{{key.subkey}}'.
 * @param {Object} data - The data object containing values to replace the placeholders in the template.
 * @returns {string} - The result string after replacing placeholders with actual values.
 *
 * @example
 * const template = "{{props.a}}/{{props.b.c}}";
 * const data = {
 *   props: {
 *     a: 1,
 *     b: {
 *       c: 2
 *     }
 *   }
 * };
 * const result = exec(template, data);
 * console.log(result); // "1/2"
 */
function replaceMustaches(template, data) {
    return template.replace(/\{\{([^}]+)\}\}/g, (match, key) => {
        const keys = key.split('.');
        let value = data;
        for (const k of keys) {
            if (value.hasOwnProperty(k)) {
                value = value[k];
            }
            else {
                return match;
            }
        }
        return String(value);
    });
}
