"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Client = void 0;
class Client {
    config;
    constructor(config) {
        this.config = config;
    }
    get(path) {
        return fetch(`${this.config.apiUrl}/${path}`).then((res) => res.json());
    }
    patch(path, body) {
        return fetch(`${this.config.apiUrl}/${path}`, {
            method: 'PATCH',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json());
    }
    post(path, body) {
        return fetch(`${this.config.apiUrl}/${path}`, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
        }).then((res) => res.json());
    }
    delete(path) {
        return fetch(`${this.config.apiUrl}/${path}`, {
            method: 'DELETE',
        }).then((res) => res.json());
    }
}
exports.Client = Client;
