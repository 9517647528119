"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KeyStorage = void 0;
const near_api_js_1 = require("near-api-js");
const LOCAL_STORAGE_KEY_PREFIX = 'near-api-js:keystore:';
class KeyStorage extends near_api_js_1.keyStores.KeyStore {
    _storage;
    prefix;
    constructor(_storage, keyStorePrefix) {
        super();
        this._storage = _storage;
        this.prefix = keyStorePrefix ?? LOCAL_STORAGE_KEY_PREFIX;
    }
    async setKey(networkId, accountId, keyPair) {
        const storageKey = this.storageKeyForSecretKey(networkId, accountId);
        await this._storage.setItem(storageKey, keyPair.toString());
        await this.registerStorageKey(storageKey);
    }
    async getKey(networkId, accountId) {
        const key = this.storageKeyForSecretKey(networkId, accountId);
        const result = await this._storage.getItem(key);
        if (!result) {
            return null;
        }
        return near_api_js_1.KeyPair.fromString(result);
    }
    async removeKey(networkId, accountId) {
        const storageKey = this.storageKeyForSecretKey(networkId, accountId);
        await this._storage.setItem(storageKey, undefined);
        await this.unregisterStorageKey(storageKey);
    }
    async clear() {
        const keys = await this.storageKeys();
        for (const key of keys) {
            if (key.startsWith(this.prefix)) {
                await this._storage.setItem(key, undefined);
            }
        }
        await this._storage.setItem(this.storageKeyForStorageKeysArray(), undefined);
    }
    async getNetworks() {
        const result = new Set();
        const keys = await this.storageKeys();
        for (const key of keys) {
            if (key.startsWith(this.prefix)) {
                const parts = key.substring(this.prefix.length).split(':');
                result.add(parts[1]);
            }
        }
        return Array.from(result.values());
    }
    async getAccounts(networkId) {
        const result = new Array();
        const keys = await this.storageKeys();
        for (const key of keys) {
            if (key.startsWith(this.prefix)) {
                const parts = key.substring(this.prefix.length).split(':');
                if (parts[1] === networkId) {
                    result.push(parts[0]);
                }
            }
        }
        return result;
    }
    storageKeyForSecretKey(networkId, accountId) {
        return `${this.prefix}${accountId}:${networkId}`;
    }
    storageKeyForStorageKeysArray() {
        return `${this.prefix}storagekeys`;
    }
    async storageKeys() {
        return (await this._storage.getItem(this.storageKeyForStorageKeysArray())) ?? [];
    }
    async registerStorageKey(storageKey) {
        const storageKeysKey = this.storageKeyForStorageKeysArray();
        const allStorageKeys = (await this._storage.getItem(storageKeysKey)) ?? [];
        await this._storage.setItem(storageKeysKey, [...allStorageKeys, storageKey]);
    }
    async unregisterStorageKey(storageKey) {
        const storageKeysKey = this.storageKeyForStorageKeysArray();
        const allStorageKeys = (await this._storage.getItem(storageKeysKey)) ?? [];
        await this._storage.setItem(storageKeysKey, allStorageKeys.filter((key) => key !== storageKey));
    }
}
exports.KeyStorage = KeyStorage;
