"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Resolution = exports.NotificationStatus = void 0;
const base_entity_1 = require("../base/base.entity");
const entity_1 = require("../base/decorators/entity");
const column_1 = require("../base/decorators/column");
var NotificationStatus;
(function (NotificationStatus) {
    NotificationStatus["New"] = "new";
    NotificationStatus["Viewed"] = "viewed";
    NotificationStatus["Hidden"] = "hidden";
})(NotificationStatus || (exports.NotificationStatus = NotificationStatus = {}));
let Resolution = class Resolution extends base_entity_1.Base {
    status = NotificationStatus.New;
    result = null;
};
exports.Resolution = Resolution;
__decorate([
    (0, column_1.Column)(),
    __metadata("design:type", String)
], Resolution.prototype, "status", void 0);
__decorate([
    (0, column_1.Column)({ type: column_1.ColumnType.Json }),
    __metadata("design:type", Object)
], Resolution.prototype, "result", void 0);
exports.Resolution = Resolution = __decorate([
    (0, entity_1.Entity)({ name: 'resolution' })
], Resolution);
