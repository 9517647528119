"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Notification = exports.NotificationType = void 0;
const base_entity_1 = require("../base/base.entity");
const entity_1 = require("../base/decorators/entity");
const column_1 = require("../base/decorators/column");
var NotificationType;
(function (NotificationType) {
    NotificationType["Regular"] = "regular";
    NotificationType["PullRequest"] = "pull-request";
    NotificationType["PullRequestAccepted"] = "pull-request-accepted";
    NotificationType["PullRequestRejected"] = "pull-request-rejected";
    NotificationType["Unknown"] = "unknown";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
let Notification = class Notification extends base_entity_1.Base {
    type = NotificationType.Unknown;
    payload = null;
    recipients = [];
};
exports.Notification = Notification;
__decorate([
    (0, column_1.Column)(),
    __metadata("design:type", String)
], Notification.prototype, "type", void 0);
__decorate([
    (0, column_1.Column)({ type: column_1.ColumnType.Json }),
    __metadata("design:type", Object)
], Notification.prototype, "payload", void 0);
__decorate([
    (0, column_1.Column)({ type: column_1.ColumnType.Set }),
    __metadata("design:type", Array)
], Notification.prototype, "recipients", void 0);
exports.Notification = Notification = __decorate([
    (0, entity_1.Entity)({ name: 'notification' })
], Notification);
