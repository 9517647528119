"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalStorage = void 0;
class LocalStorage {
    _keyPrefix;
    constructor(_keyPrefix = 'mutableweb') {
        this._keyPrefix = _keyPrefix;
    }
    async getItem(key) {
        return localStorage.getItem(this._makeKey(key));
    }
    async setItem(key, value) {
        localStorage.setItem(this._makeKey(key), value);
    }
    async removeItem(key) {
        localStorage.removeItem(this._makeKey(key));
    }
    async getAllKeys() {
        return Object.keys(localStorage)
            .filter((key) => key.startsWith(this._keyPrefix))
            .map((key) => key.substring(this._keyPrefix.length + 1));
    }
    _makeKey(key) {
        return this._keyPrefix + ':' + key;
    }
}
exports.LocalStorage = LocalStorage;
