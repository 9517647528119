"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.utils = exports.InMemoryStorage = exports.getRedirectMap = exports.ParserConfig = exports.PullRequestStatus = exports.getNearConfig = exports.NotificationType = exports.LocalStorage = exports.EntitySourceType = exports.Engine = void 0;
var engine_1 = require("./engine");
Object.defineProperty(exports, "Engine", { enumerable: true, get: function () { return engine_1.Engine; } });
var base_entity_1 = require("./services/base/base.entity");
Object.defineProperty(exports, "EntitySourceType", { enumerable: true, get: function () { return base_entity_1.EntitySourceType; } });
var local_storage_1 = require("./services/local-db/local-storage");
Object.defineProperty(exports, "LocalStorage", { enumerable: true, get: function () { return local_storage_1.LocalStorage; } });
var notification_entity_1 = require("./services/notification/notification.entity");
Object.defineProperty(exports, "NotificationType", { enumerable: true, get: function () { return notification_entity_1.NotificationType; } });
var config_1 = require("./config");
Object.defineProperty(exports, "getNearConfig", { enumerable: true, get: function () { return config_1.getNearConfig; } });
var pull_request_1 = require("./services/notification/types/pull-request");
Object.defineProperty(exports, "PullRequestStatus", { enumerable: true, get: function () { return pull_request_1.PullRequestStatus; } });
// ToDo: replace with DTO
var parser_config_entity_1 = require("./services/parser-config/parser-config.entity");
Object.defineProperty(exports, "ParserConfig", { enumerable: true, get: function () { return parser_config_entity_1.ParserConfig; } });
// ToDo: move getRedirectMap to services
var dev_server_service_1 = require("./services/dev-server-service");
Object.defineProperty(exports, "getRedirectMap", { enumerable: true, get: function () { return dev_server_service_1.getRedirectMap; } });
const application_service_1 = require("./services/application/application.service");
const target_service_1 = require("./services/target/target.service");
var in_memory_storage_1 = require("./services/local-db/in-memory-storage");
Object.defineProperty(exports, "InMemoryStorage", { enumerable: true, get: function () { return in_memory_storage_1.InMemoryStorage; } });
exports.utils = {
    isTargetMet: target_service_1.TargetService.isTargetMet.bind(target_service_1.TargetService),
    getRootContext: target_service_1.TargetService.getRootContext.bind(target_service_1.TargetService),
    findContextByTarget: target_service_1.TargetService.findContextByTarget.bind(target_service_1.TargetService),
    constructAppInstanceId: application_service_1.ApplicationService.constructAppInstanceId.bind(target_service_1.TargetService),
};
