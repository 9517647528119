"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BaseLocalRepository = void 0;
const base_entity_1 = require("./base.entity");
const entity_1 = require("./decorators/entity");
const is_deep_equal_1 = require("../../common/is-deep-equal");
const KeyDelimiter = '/';
class BaseLocalRepository {
    EntityType;
    storage;
    _entityKey;
    constructor(EntityType, storage) {
        this.EntityType = EntityType;
        this.storage = storage;
        this._entityKey = (0, entity_1.getEntity)(EntityType).name;
    }
    async getItem({ id, version }) {
        const parsedId = BaseLocalRepository._parseGlobalId(id);
        if (!parsedId)
            return null;
        const json = await this.storage.getItem(id);
        if (!json)
            return null;
        const history = json ? JSON.parse(json) ?? [] : [];
        if (!history)
            return null;
        const raw = history.pop();
        if (!raw)
            return null;
        // @ts-ignore
        const entity = this.EntityType.create({ source: base_entity_1.EntitySourceType.Local, ...raw });
        return entity;
    }
    async getItems(options) {
        const keys = await this.storage.getAllKeys();
        const filteredKeys = keys.filter((key) => {
            const parsedId = BaseLocalRepository._parseGlobalId(key);
            if (!parsedId)
                return false;
            if (options?.authorId && parsedId.authorId !== options.authorId)
                return false;
            if (options?.localId && parsedId.localId !== options.localId)
                return false;
            if (parsedId.type !== this._entityKey)
                return false;
            return true;
        });
        const items = await Promise.all(filteredKeys.map((id) => this.getItem({ id })));
        return items.filter((x) => x !== null);
    }
    async getItemsByIndex(entity) {
        const items = await this.getItems();
        const filteredItems = items.filter((item) => {
            for (const prop in entity) {
                if (!(0, is_deep_equal_1.isDeepEqual)(entity[prop], item[prop]))
                    return false;
            }
            return true;
        });
        return filteredItems;
    }
    async createItem(item) {
        if (await this.getItem({ id: item.id })) {
            throw new Error('Item with that ID already exists');
        }
        await this.saveItem(item);
        // ToDo: update timestamp and blockNumber
        return item;
    }
    async editItem(item) {
        if (!(await this.getItem({ id: item.id }))) {
            throw new Error('Item with that ID does not exist');
        }
        await this.saveItem(item);
        // ToDo: update timestamp and blockNumber
        return item;
    }
    async saveItem(item) {
        const json = await this.storage.getItem(item.id);
        const history = json ? JSON.parse(json) ?? [] : [];
        history.push(item);
        await this.storage.setItem(item.id, JSON.stringify(history));
        return item;
    }
    async deleteItem(id) {
        await this.storage.removeItem(id);
    }
    async constructItem(item) {
        if (!item?.metadata?.name) {
            throw new Error('Metadata name is required');
        }
        const localId = BaseLocalRepository._normalizeNameToLocalId(item.metadata.name);
        // ToDo: magic value
        const authorId = null;
        // @ts-ignore
        const entity = this.EntityType.create({
            ...item,
            localId,
            authorId,
            source: base_entity_1.EntitySourceType.Local,
        });
        return entity;
    }
    async getVersions(options) {
        throw new Error('Method not implemented.');
    }
    async getTagValue(options) {
        throw new Error('Method not implemented.');
    }
    async getTags(options) {
        throw new Error('Method not implemented.');
    }
    static _parseGlobalId(globalId) {
        if (globalId.split(KeyDelimiter).length !== 3) {
            return null;
        }
        if (globalId.replaceAll(/[^a-zA-Z0-9_.\-\/]/g, '') !== globalId) {
            return null;
        }
        const [authorId, type, localId] = globalId.split(KeyDelimiter);
        return { authorId, type, localId };
    }
    // ToDo: duplicate in base.repository.ts
    static _normalizeNameToLocalId(name) {
        // allow only alphanumeric
        return name.replace(/[^a-zA-Z0-9]/g, '');
    }
}
exports.BaseLocalRepository = BaseLocalRepository;
