"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParserType = void 0;
var ParserType;
(function (ParserType) {
    ParserType["Bos"] = "bos";
    ParserType["Microdata"] = "microdata";
    ParserType["Json"] = "json";
    ParserType["MWeb"] = "mweb";
    ParserType["Link"] = "link";
    ParserType["Unknown"] = "unknown";
})(ParserType || (exports.ParserType = ParserType = {}));
