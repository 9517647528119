"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MutationService = void 0;
const target_service_1 = require("../target/target.service");
const mutation_entity_1 = require("./mutation.entity");
const notification_entity_1 = require("../notification/notification.entity");
const base_entity_1 = require("../base/base.entity");
class MutationService {
    mutationRepository;
    settingsService;
    notificationService;
    unitOfWorkService;
    nearConfig;
    nearSigner;
    constructor(mutationRepository, settingsService, notificationService, unitOfWorkService, nearConfig, nearSigner) {
        this.mutationRepository = mutationRepository;
        this.settingsService = settingsService;
        this.notificationService = notificationService;
        this.unitOfWorkService = unitOfWorkService;
        this.nearConfig = nearConfig;
        this.nearSigner = nearSigner;
    }
    async getMutation(mutationId, source, version) {
        const mutation = await this.mutationRepository.getItem({ id: mutationId, source, version });
        return mutation?.toDto() ?? null;
    }
    async getMutationVersions(mutationId) {
        const versions = await this.mutationRepository.getVersions({
            id: mutationId,
            source: base_entity_1.EntitySourceType.Origin,
        });
        return versions.map((v) => ({ version: v }));
    }
    async getMutationsForContext(context) {
        const mutations = await this.mutationRepository.getItems();
        return mutations
            .filter((mutation) => mutation.targets.some((target) => target_service_1.TargetService.isTargetMet(target, context)))
            .map((mutation) => mutation.toDto());
    }
    async getMutationsWithSettings(context) {
        const mutations = await this.getMutationsForContext(context);
        return Promise.all(mutations.map((mut) => this.populateMutationWithSettings(mut)));
    }
    getLastUsedMutation = async (context) => {
        const allMutations = await this.getMutationsWithSettings(context);
        const hostname = window.location.hostname;
        const lastUsedData = await Promise.all(allMutations.map(async (m) => ({
            id: m.id,
            lastUsage: await this.settingsService.getMutationLastUsage(m.id, hostname),
        })));
        const usedMutationsData = lastUsedData
            .filter((m) => m.lastUsage)
            .map((m) => ({ id: m.id, lastUsage: new Date(m.lastUsage).getTime() }));
        if (usedMutationsData?.length) {
            if (usedMutationsData.length === 1)
                return usedMutationsData[0].id;
            let lastMutation = usedMutationsData[0];
            for (let i = 1; i < usedMutationsData.length; i++) {
                if (usedMutationsData[i].lastUsage > lastMutation.lastUsage) {
                    lastMutation = usedMutationsData[i];
                }
            }
            return lastMutation.id;
        }
        else {
            // Activate default mutation for new users
            return this.nearConfig.defaultMutationId;
        }
    };
    async setFavoriteMutation(mutationId) {
        return this.settingsService.setFavoriteMutation(mutationId);
    }
    async getFavoriteMutation() {
        const value = await this.settingsService.getFavoriteMutation();
        return value ?? null;
    }
    async getPreferredSource(mutationId) {
        const value = await this.settingsService.getPreferredSource(mutationId);
        return value ?? null;
    }
    async getMutationVersion(mutationId) {
        const value = await this.settingsService.getMutationVersion(mutationId);
        return value ?? null;
    }
    async setMutationVersion(mutationId, version = null) {
        return this.settingsService.setMutationVersion(mutationId, version);
    }
    async setPreferredSource(mutationId, source) {
        return this.settingsService.setPreferredSource(mutationId, source);
    }
    async createMutation(dto, options = {
        applyChangesToOrigin: false,
        askOriginToApplyChanges: false,
    }) {
        const { applyChangesToOrigin, askOriginToApplyChanges } = options;
        const mutation = await this._fixMutationErrors(await this.mutationRepository.constructItem(dto));
        if (mutation.source === base_entity_1.EntitySourceType.Origin) {
            await this.unitOfWorkService.runInTransaction((tx) => Promise.all([
                this.mutationRepository.createItem(mutation, tx),
                applyChangesToOrigin && this._applyChangesToOrigin(mutation, tx),
                askOriginToApplyChanges && this._askOriginToApplyChanges(mutation, tx),
            ]));
        }
        else if (mutation.source === base_entity_1.EntitySourceType.Local) {
            await this.mutationRepository.createItem(mutation);
        }
        else {
            throw new Error('Invalid entity source');
        }
        return this.populateMutationWithSettings(mutation.toDto());
    }
    async editMutation(dto, options = {
        applyChangesToOrigin: false,
        askOriginToApplyChanges: false,
    }, tx) {
        const { applyChangesToOrigin, askOriginToApplyChanges } = options;
        const mutation = await this._fixMutationErrors(mutation_entity_1.Mutation.create(dto));
        // ToDo: move to provider?
        if (!(await this.mutationRepository.getItem({ id: mutation.id }))) {
            throw new Error('Mutation with that ID does not exist');
        }
        let editedMutation;
        if (mutation.source === base_entity_1.EntitySourceType.Origin) {
            const performTx = (tx) => Promise.all([
                this.mutationRepository.editItem(mutation, tx),
                applyChangesToOrigin && this._applyChangesToOrigin(mutation, tx),
                askOriginToApplyChanges && this._askOriginToApplyChanges(mutation, tx),
            ]);
            // reuse transaction
            if (tx) {
                const result = await performTx(tx);
                editedMutation = result[0];
            }
            else {
                const result = await this.unitOfWorkService.runInTransaction(performTx);
                editedMutation = result[0];
            }
        }
        else if (mutation.source === base_entity_1.EntitySourceType.Local) {
            editedMutation = await this.mutationRepository.editItem(mutation, tx);
        }
        else {
            throw new Error('Invalid entity source');
        }
        return this.populateMutationWithSettings(editedMutation.toDto());
    }
    async saveMutation(dto, options = {
        applyChangesToOrigin: false,
        askOriginToApplyChanges: false,
    }, tx) {
        const { applyChangesToOrigin, askOriginToApplyChanges } = options;
        const mutation = await this._fixMutationErrors('id' in dto ? mutation_entity_1.Mutation.create(dto) : await this.mutationRepository.constructItem(dto));
        if (mutation.source === base_entity_1.EntitySourceType.Origin) {
            const performTx = (tx) => Promise.all([
                this.mutationRepository.saveItem(mutation, tx),
                applyChangesToOrigin && this._applyChangesToOrigin(mutation, tx),
                askOriginToApplyChanges && this._askOriginToApplyChanges(mutation, tx),
            ]);
            // reuse transaction
            if (tx) {
                await performTx(tx);
            }
            else {
                await this.unitOfWorkService.runInTransaction(performTx);
            }
        }
        else if (mutation.source === base_entity_1.EntitySourceType.Local) {
            await this.mutationRepository.saveItem(mutation, tx);
        }
        else {
            throw new Error('Invalid entity source');
        }
        return this.populateMutationWithSettings(mutation.toDto());
    }
    async deleteMutation(mutationId) {
        await this.mutationRepository.deleteItem(mutationId);
    }
    async acceptPullRequest(notificationId) {
        const notification = await this.notificationService.getNotification(notificationId);
        if (!notification) {
            throw new Error('Notification not found');
        }
        if (notification.type !== notification_entity_1.NotificationType.PullRequest) {
            throw new Error('Notification is not a pull request');
        }
        const { sourceMutationId, targetMutationId } = notification.payload;
        const sourceMutation = await this.mutationRepository.getItem({ id: sourceMutationId });
        if (!sourceMutation) {
            throw new Error('Source mutation not found');
        }
        if (sourceMutation.metadata.fork_of !== targetMutationId) {
            throw new Error('Source mutation is not fork of target mutation');
        }
        const [, freshNotification] = await this.unitOfWorkService.runInTransaction((tx) => Promise.all([
            this._applyChangesToOrigin(sourceMutation, tx),
            this.notificationService.acceptNotification(notificationId, tx),
            this._notifyAboutAcceptedPullRequest(sourceMutationId, targetMutationId, tx),
        ]));
        return freshNotification;
    }
    async rejectPullRequest(notificationId) {
        const notification = await this.notificationService.getNotification(notificationId);
        if (!notification) {
            throw new Error('Notification not found');
        }
        if (notification.type !== notification_entity_1.NotificationType.PullRequest) {
            throw new Error('Notification is not a pull request');
        }
        const { sourceMutationId, targetMutationId } = notification.payload;
        const [freshNotification] = await this.unitOfWorkService.runInTransaction((tx) => Promise.all([
            this.notificationService.rejectNotification(notificationId, tx),
            this._notifyAboutRejectedPullRequest(sourceMutationId, targetMutationId, tx),
        ]));
        return freshNotification;
    }
    async removeMutationFromRecents(mutationId) {
        await this.settingsService.setMutationLastUsage(mutationId, null, window.location.hostname);
    }
    async updateMutationLastUsage(mutationId, hostname) {
        // save last usage
        const currentDate = new Date().toISOString();
        await this.settingsService.setMutationLastUsage(mutationId, currentDate, hostname);
        return currentDate;
    }
    async getMutationWithSettings(mutationId, source, version) {
        const mutation = await this.getMutation(mutationId, source, version);
        return mutation ? this.populateMutationWithSettings(mutation) : null;
    }
    async populateMutationWithSettings(mutation) {
        const lastUsage = await this.settingsService.getMutationLastUsage(mutation.id, window.location.hostname);
        // ToDo: do not mix MutationWithSettings and Mutation
        return { ...mutation, settings: { lastUsage } };
    }
    async _applyChangesToOrigin(forkedMutation, tx) {
        const originalMutationId = forkedMutation.metadata.fork_of;
        if (!originalMutationId) {
            throw new Error('The mutation is not a fork and does not have an origin to apply changes to');
        }
        const originalMutation = await this.mutationRepository.getItem({ id: originalMutationId });
        if (!originalMutation) {
            throw new Error('The origin mutation does not exist');
        }
        // apply changes to origin
        originalMutation.apps = forkedMutation.apps;
        originalMutation.metadata.description = forkedMutation.metadata.description;
        originalMutation.targets = forkedMutation.targets;
        await this.mutationRepository.editItem(originalMutation, tx);
        return originalMutation;
    }
    async _askOriginToApplyChanges(forkedMutation, tx) {
        const originalMutationId = forkedMutation.metadata.fork_of;
        if (!originalMutationId) {
            throw new Error('The mutation is not a fork and does not have an origin to apply changes to');
        }
        const originalMutation = await this.mutationRepository.getItem({ id: originalMutationId });
        if (!originalMutation) {
            throw new Error('The origin mutation does not exist');
        }
        const { authorId: forkAuthorId } = forkedMutation;
        const { authorId: originAuthorId } = originalMutation;
        if (!originAuthorId || !forkAuthorId) {
            throw new Error('The mutation does not have an author');
        }
        // ToDo: check logged in user id?
        if (forkAuthorId === originAuthorId) {
            throw new Error('You cannot ask yourself to apply changes');
        }
        const notification = {
            source: base_entity_1.EntitySourceType.Origin,
            type: notification_entity_1.NotificationType.PullRequest,
            recipients: [originAuthorId],
            payload: {
                sourceMutationId: forkedMutation.id,
                targetMutationId: originalMutation.id,
            },
        };
        await this.notificationService.createNotification(notification, tx);
    }
    async _fixMutationErrors(mutation) {
        if (mutation.source === base_entity_1.EntitySourceType.Local) {
            return mutation;
        }
        const accountId = await this.nearSigner.getAccountId();
        mutation.apps = mutation.apps.map((app) => {
            if (!app.documentId)
                return app;
            const [docAuthorId, , localDocId] = app.documentId.split('/');
            if (docAuthorId)
                return app;
            return {
                appId: app.appId,
                documentId: `${accountId}/document/${localDocId}`,
            };
        });
        return mutation;
    }
    async _notifyAboutAcceptedPullRequest(sourceMutationId, targetMutationId, tx) {
        const [sourceAuthorId] = sourceMutationId.split('/');
        const notification = {
            source: base_entity_1.EntitySourceType.Origin,
            type: notification_entity_1.NotificationType.PullRequestAccepted,
            recipients: [sourceAuthorId],
            payload: {
                sourceMutationId: sourceMutationId,
                targetMutationId: targetMutationId,
            },
        };
        await this.notificationService.createNotification(notification, tx);
    }
    async _notifyAboutRejectedPullRequest(sourceMutationId, targetMutationId, tx) {
        const [sourceAuthorId] = sourceMutationId.split('/');
        const notification = {
            source: base_entity_1.EntitySourceType.Origin,
            type: notification_entity_1.NotificationType.PullRequestRejected,
            recipients: [sourceAuthorId],
            payload: {
                sourceMutationId: sourceMutationId,
                targetMutationId: targetMutationId,
            },
        };
        await this.notificationService.createNotification(notification, tx);
    }
}
exports.MutationService = MutationService;
