"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ParserConfigService = void 0;
const application_entity_1 = require("../application/application.entity");
class ParserConfigService {
    parserConfigRepository;
    constructor(parserConfigRepository) {
        this.parserConfigRepository = parserConfigRepository;
    }
    async getParserConfig(parserId) {
        if (parserId === 'mweb')
            return null;
        if (parserId === 'engine')
            return null;
        return this.parserConfigRepository.getItem({ id: parserId });
    }
    async getAllParserConfigs() {
        return this.parserConfigRepository.getItems();
    }
    async getParserConfigsForApps(apps) {
        const namespaces = new Set();
        for (const app of apps) {
            if (!app.parsers) {
                app.targets.forEach((target) => namespaces.add(target.namespace));
            }
            else if (app.parsers === application_entity_1.AnyParserValue) {
                return this.getAllParserConfigs(); // returns all parsers and breaks the loop!
            }
            else {
                app.parsers.forEach((parserGlobalId) => namespaces.add(parserGlobalId));
            }
        }
        // ToDo: catch errors
        const parserConfigs = await Promise.all(Array.from(namespaces).map((ns) => this.getParserConfig(ns)));
        return parserConfigs.filter((pc) => pc !== null);
    }
}
exports.ParserConfigService = ParserConfigService;
