"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrawlerSdk = void 0;
const client_1 = require("./client");
const context_1 = require("./context");
const order_1 = require("./order");
class CrawlerSdk {
    _client;
    order;
    context;
    constructor(config) {
        this._client = new client_1.Client(config);
        this.order = new order_1.OrderService(this._client);
        this.context = new context_1.ContextService(this._client);
    }
}
exports.CrawlerSdk = CrawlerSdk;
