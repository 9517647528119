"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getRedirectMap = void 0;
const getRedirectMap = async (bosLoaderUrl) => {
    const res = await fetch(bosLoaderUrl, {
        method: 'GET',
        headers: { Accept: 'application/json' },
    });
    if (!res.ok) {
        throw new Error('Dev server is not available');
    }
    const data = await res.json();
    return data?.components ?? null;
};
exports.getRedirectMap = getRedirectMap;
