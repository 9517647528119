"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColumnType = void 0;
exports.Column = Column;
exports.getColumn = getColumn;
require("reflect-metadata/lite");
const Key = Symbol('column');
var ColumnType;
(function (ColumnType) {
    ColumnType["Json"] = "json";
    ColumnType["AsIs"] = "asis";
    ColumnType["Set"] = "set";
})(ColumnType || (exports.ColumnType = ColumnType = {}));
function Column(options = { type: ColumnType.AsIs }) {
    if (!options.type) {
        options.type = ColumnType.AsIs;
    }
    return Reflect.metadata(Key, options);
}
function getColumn(target, propertyKey) {
    return Reflect.getMetadata(Key, target, propertyKey);
}
