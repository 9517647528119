"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LocalDbService = void 0;
const KEY_DELIMITER = ':';
/**
 * ToDo: rename to DataSource
 */
class LocalDbService {
    storage;
    constructor(storage) {
        this.storage = storage;
    }
    async getItem(key) {
        const item = await this.storage.getItem(key);
        return typeof item === 'string' ? JSON.parse(item) : undefined;
    }
    async setItem(key, value) {
        const serializedValue = JSON.stringify(value);
        if (typeof serializedValue === 'undefined') {
            return this.storage.removeItem(key);
        }
        else {
            return this.storage.setItem(key, JSON.stringify(value));
        }
    }
    static makeKey(...keys) {
        return keys.join(KEY_DELIMITER);
    }
}
exports.LocalDbService = LocalDbService;
