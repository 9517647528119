"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
const base_entity_1 = require("../base/base.entity");
const user_link_service_1 = require("../user-link/user-link.service");
const notification_entity_1 = require("./notification.entity");
const resolution_entity_1 = require("./resolution.entity");
const pull_request_1 = require("./types/pull-request");
const generate_guid_1 = require("../../common/generate-guid");
const KeyDelimiter = '/';
class NotificationService {
    notificationRepository;
    resolutionRepository;
    unitOfWorkService;
    nearSigner;
    constructor(notificationRepository, resolutionRepository, unitOfWorkService, nearSigner) {
        this.notificationRepository = notificationRepository;
        this.resolutionRepository = resolutionRepository;
        this.unitOfWorkService = unitOfWorkService;
        this.nearSigner = nearSigner;
    }
    // ToDo: return dto
    async getNotification(notificationId) {
        return this.notificationRepository.getItem({ id: notificationId });
    }
    async createNotification(dto, tx) {
        const authorId = await this.nearSigner.getAccountId();
        if (!authorId) {
            throw new Error('Near account is not signed in');
        }
        const localId = (0, generate_guid_1.generateGuid)();
        const notification = notification_entity_1.Notification.create({ ...dto, authorId, localId });
        await this.notificationRepository.createItem(notification, tx);
    }
    // ToDo: move DTOs to controllers?
    async getMyNotifications(accountId) {
        if (!accountId)
            return [];
        const incomingNotifications = await this.notificationRepository.getItemsByIndex({
            recipients: [accountId],
        });
        const outgoingNotifications = await this.notificationRepository.getItems({
            authorId: accountId,
        });
        const allNotifications = incomingNotifications;
        for (const outgoingNotification of outgoingNotifications) {
            if (!allNotifications.some((n) => n.id === outgoingNotification.id)) {
                allNotifications.push(outgoingNotification);
            }
        }
        const resolutions = await Promise.all(allNotifications.map((notification) => this._getResolutionForNotification(notification.id, notification.type, accountId)));
        return allNotifications.map((notification, i) => {
            const resolution = resolutions[i];
            return this._toDto(notification, resolution);
        });
    }
    async viewNotification(notificationId, tx) {
        return this._resolveNotificationById(notificationId, (resolution) => {
            if (resolution.status === resolution_entity_1.NotificationStatus.Viewed) {
                throw new Error('Notification is already viewed');
            }
            resolution.status = resolution_entity_1.NotificationStatus.Viewed;
        }, tx);
    }
    async viewAllNotifcations(recipientId) {
        const notifications = await this.getMyNotifications(recipientId);
        const notificationsToBeViewed = notifications.filter((notification) => notification.status === resolution_entity_1.NotificationStatus.New);
        const markNotificationAsViewed = (resolution) => {
            resolution.status = resolution_entity_1.NotificationStatus.Viewed;
        };
        return this.unitOfWorkService.runInTransaction((tx) => Promise.all(notificationsToBeViewed.map((notification) => this._resolveNotificationById(notification.id, markNotificationAsViewed, tx))));
    }
    async hideNotification(notificationId, tx) {
        return this._resolveNotificationById(notificationId, (resolution) => {
            if (resolution.status === resolution_entity_1.NotificationStatus.Hidden) {
                throw new Error('Notification is already hidden');
            }
            resolution.status = resolution_entity_1.NotificationStatus.Hidden;
        }, tx);
    }
    async acceptNotification(notificationId, tx) {
        return this._resolveNotificationById(notificationId, (resolution, notification) => {
            if (notification.type !== notification_entity_1.NotificationType.PullRequest) {
                throw new Error('Notification is not a pull request');
            }
            if (resolution.status === resolution_entity_1.NotificationStatus.Hidden) {
                throw new Error('Notification is hidden');
            }
            if (resolution.result?.status === pull_request_1.PullRequestStatus.Accepted ||
                resolution.result?.status === pull_request_1.PullRequestStatus.Rejected) {
                throw new Error('Notification has already been resolved');
            }
            // ToDo: check resolution.result
            resolution.status = resolution_entity_1.NotificationStatus.Viewed;
            resolution.result = { status: pull_request_1.PullRequestStatus.Accepted };
        }, tx);
    }
    async rejectNotification(notificationId, tx) {
        return this._resolveNotificationById(notificationId, (resolution, notification) => {
            if (notification.type !== notification_entity_1.NotificationType.PullRequest) {
                throw new Error('Notification is not a pull request');
            }
            if (resolution.status === resolution_entity_1.NotificationStatus.Hidden) {
                throw new Error('Notification is hidden');
            }
            if (resolution.result?.status === pull_request_1.PullRequestStatus.Accepted ||
                resolution.result?.status === pull_request_1.PullRequestStatus.Rejected) {
                throw new Error('Notification has already been resolved');
            }
            resolution.status = resolution_entity_1.NotificationStatus.Viewed;
            resolution.result = { status: pull_request_1.PullRequestStatus.Rejected };
        }, tx);
    }
    async _resolveNotificationById(notificationId, callback, tx) {
        const notification = await this.notificationRepository.getItem({ id: notificationId });
        if (!notification) {
            throw new Error('Notification not found');
        }
        return this._resolveNotification(notification, callback, tx);
    }
    async _resolveNotification(notification, callback, tx) {
        const accountId = await this.nearSigner.getAccountId();
        if (!accountId) {
            throw new Error('Not logged in');
        }
        //todo: commented, but not resolved
        // if (!notification.recipients.includes(accountId)) {
        //   throw new Error('You are not a recipient of this notification')
        // }
        const resolution = await this._getResolutionForNotification(notification.id, notification.type, accountId);
        callback(resolution, notification);
        await this.resolutionRepository.saveItem(resolution, tx);
        // ToDo: use mappers or move to entity method
        return this._toDto(notification, resolution);
    }
    async _getResolutionForNotification(notificationId, notificationType, recipientId) {
        const [senderId] = notificationId.split(KeyDelimiter);
        const hash = user_link_service_1.UserLinkService._hashString(notificationId);
        const resolutionId = `${recipientId}/resolution/${hash}`;
        const resolution = await this.resolutionRepository.getItem({ id: resolutionId });
        if (resolution)
            return resolution;
        // ToDo: refactor to add new notifcation type simply
        // ToDo: split to separate methods?
        switch (notificationType) {
            case notification_entity_1.NotificationType.PullRequest:
                return resolution_entity_1.Resolution.create({
                    id: resolutionId,
                    source: base_entity_1.EntitySourceType.Origin,
                    // ToDo: outgoing notifications are viewed by default
                    status: recipientId === senderId ? resolution_entity_1.NotificationStatus.Viewed : resolution_entity_1.NotificationStatus.New,
                    result: { status: pull_request_1.PullRequestStatus.Open },
                });
            case notification_entity_1.NotificationType.Regular:
            default:
                return resolution_entity_1.Resolution.create({
                    id: resolutionId,
                    source: base_entity_1.EntitySourceType.Origin,
                    // ToDo: outgoing notifications are viewed by default
                    status: recipientId === senderId ? resolution_entity_1.NotificationStatus.Viewed : resolution_entity_1.NotificationStatus.New,
                });
        }
    }
    _toDto(notification, resolution) {
        return {
            id: notification.id,
            source: notification.source,
            localId: notification.localId,
            authorId: notification.authorId,
            blockNumber: notification.blockNumber,
            timestamp: notification.timestamp,
            type: notification.type,
            payload: notification.payload,
            recipients: notification.recipients,
            result: resolution.result,
            status: resolution.status,
            version: notification.version,
        };
    }
}
exports.NotificationService = NotificationService;
