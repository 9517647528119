"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContextService = void 0;
class ContextService {
    client;
    constructor(client) {
        this.client = client;
    }
    findAll = async () => {
        return this.client.get('context');
    };
    save = async (contextDto) => {
        return this.client.post('context', contextDto);
    };
    get = async (hash) => {
        return this.client.get(`context/${hash}`);
    };
}
exports.ContextService = ContextService;
