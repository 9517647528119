"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsSerivce = void 0;
const local_db_service_1 = require("../local-db/local-db.service");
// Local DB
const FAVORITE_MUTATION = 'favorite-mutation';
const PREFERRED_SOURCE = 'preferred-source';
const MUTATION_LAST_USAGE = 'mutation-last-usage';
const STOPPED_APPS = 'stopped-apps';
const MUTATION_VERSION = 'mutation-version';
class SettingsSerivce {
    localDb;
    constructor(localDb) {
        this.localDb = localDb;
    }
    async getFavoriteMutation() {
        const key = local_db_service_1.LocalDbService.makeKey(FAVORITE_MUTATION, window.location.hostname);
        return this.localDb.getItem(key);
    }
    async setFavoriteMutation(mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(FAVORITE_MUTATION, window.location.hostname);
        return this.localDb.setItem(key, mutationId);
    }
    async getPreferredSource(mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(PREFERRED_SOURCE, mutationId, window.location.hostname);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setPreferredSource(mutationId, source) {
        const key = local_db_service_1.LocalDbService.makeKey(PREFERRED_SOURCE, mutationId, window.location.hostname);
        return this.localDb.setItem(key, source);
    }
    async getMutationLastUsage(mutationId, hostname) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_LAST_USAGE, mutationId, hostname);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setMutationLastUsage(mutationId, value, hostname) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_LAST_USAGE, mutationId, hostname);
        return this.localDb.setItem(key, value);
    }
    async getAppEnabledStatus(mutationId, appInstanceId) {
        const key = local_db_service_1.LocalDbService.makeKey(STOPPED_APPS, mutationId, appInstanceId);
        return (await this.localDb.getItem(key)) ?? true; // app is active by default
    }
    async setAppEnabledStatus(mutationId, appInstanceId, isEnabled) {
        const key = local_db_service_1.LocalDbService.makeKey(STOPPED_APPS, mutationId, appInstanceId);
        return this.localDb.setItem(key, isEnabled);
    }
    async getMutationVersion(mutationId) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_VERSION, mutationId);
        return (await this.localDb.getItem(key)) ?? null;
    }
    async setMutationVersion(mutationId, version = null) {
        const key = local_db_service_1.LocalDbService.makeKey(MUTATION_VERSION, mutationId);
        return this.localDb.setItem(key, version);
    }
}
exports.SettingsSerivce = SettingsSerivce;
