"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IndexedLink = void 0;
const base_entity_1 = require("../base/base.entity");
const column_1 = require("../base/decorators/column");
const entity_1 = require("../base/decorators/entity");
let IndexedLink = class IndexedLink extends base_entity_1.Base {
    indexes = [];
};
exports.IndexedLink = IndexedLink;
__decorate([
    (0, column_1.Column)({ type: column_1.ColumnType.Set, versioned: false }),
    __metadata("design:type", Array)
], IndexedLink.prototype, "indexes", void 0);
exports.IndexedLink = IndexedLink = __decorate([
    (0, entity_1.Entity)({ name: 'link' })
], IndexedLink);
